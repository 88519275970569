@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";


/* ============================================================================
Customlink component
============================================================================ */

$ex: ex-custom-link;

/* ============================================================================
Common styles updates
============================================================================ */


/* ============================================================================
Custom styles
============================================================================ */

.#{$ex} {
    a {
        color: var(--color);
        text-decoration: none;
        background-color: var(--background-color);
        border-radius: var(--border-radius);
        width: var(--width);
        font-weight: var(--font-weight);
        letter-spacing: var(--letter-spacing);
        position: var(--position);
        top: var(--top);
        right: var(--right);
        bottom: var(--bottom);
        left: var(--left);
        transform: var(--transform);
        padding-top: var(--padding-top);
        padding-right: var(--padding-right);
        padding-bottom: var(--padding-bottom);
        padding-left: var(--padding-left);

        @include bp(min-width, $bp-medium) {
            width: var(--width-md);
            font-weight: var(--font-weight-md);
            letter-spacing: var(--letter-spacing-md);
            position: var(--position-md);
            top: var(--top-md);
            right: var(--right-md);
            bottom: var(--bottom-md);
            left: var(--left-md);
            transform: var(--transform-md);
            padding-top: var(--padding-top-md);
            padding-right: var(--padding-right-md);
            padding-bottom: var(--padding-bottom-md);
            padding-left: var(--padding-left-md);
        }

        &:hover {
            color: var(--color-hover);
            background-color: var(--background-color-hover);
        }

        &:active {
            color: var(--color-active);
            background-color: var(--background-color-active);
            text-decoration: none;
        }

        &.show-custom-underline {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background: $black;
            }
        }

        &.lifestyle-text-decoration-underline {
            text-decoration: underline;
        }
    }
}
